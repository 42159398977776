import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Match = {
  __typename?: 'Match';
  id: Scalars['Int'];
  morphemeIds: Array<Scalars['Int']>;
};

export type MistakeRuleResult = {
  __typename?: 'MistakeRuleResult';
  matchIds: Array<Scalars['Int']>;
  reason: Scalars['String'];
  tagSet: Array<Scalars['String']>;
};

export type Morpheme = {
  __typename?: 'Morpheme';
  id?: Maybe<Scalars['Int']>;
  originalSurface?: Maybe<Scalars['String']>;
  surface?: Maybe<Scalars['String']>;
  wordClass?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  proofread?: Maybe<ProofreadPayload>;
};


export type MutationProofreadArgs = {
  input: ProofreadInput;
};

/** Autogenerated input type of Proofread */
export type ProofreadInput = {
  taskId: Scalars['ID'];
  text: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Proofread */
export type ProofreadPayload = {
  __typename?: 'ProofreadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: ProofreadingResult;
};

export type ProofreadingResult = {
  __typename?: 'ProofreadingResult';
  body: Array<Morpheme>;
  matches: Array<Match>;
  mistakeRuleResults: Array<MistakeRuleResult>;
  ratingMessages: Array<Scalars['String']>;
  requirementRuleResults: Array<RequirementRuleResult>;
  styleRuleResults: Array<StyleRuleResult>;
};

export type Query = {
  __typename?: 'Query';
  task?: Maybe<Task>;
  tasks: Array<Task>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};

export type RequirementRuleResult = {
  __typename?: 'RequirementRuleResult';
  matchIds: Array<Scalars['Int']>;
  reason: Scalars['String'];
  tagSet: Array<Scalars['String']>;
};

export type StyleRuleResult = {
  __typename?: 'StyleRuleResult';
  matchIds: Array<Scalars['Int']>;
  reason: Scalars['String'];
  tagSet: Array<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  description: Scalars['String'];
  displayName: Scalars['String'];
  figureUrl?: Maybe<Scalars['String']>;
  gradeName: Scalars['String'];
  id: Scalars['ID'];
  nextTaskId?: Maybe<Scalars['Int']>;
  numberInGrade: Scalars['Int'];
  questionText: Scalars['String'];
};

export type TaskDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskDetailQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & TaskDetailFragment
  )> }
);

export type TaskDetailFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'displayName' | 'questionText' | 'figureUrl' | 'nextTaskId'>
);

export type ProofreadMutationVariables = Exact<{
  id: Scalars['ID'];
  text: Scalars['String'];
}>;


export type ProofreadMutation = (
  { __typename?: 'Mutation' }
  & { proofread?: Maybe<(
    { __typename?: 'ProofreadPayload' }
    & { result: (
      { __typename?: 'ProofreadingResult' }
      & ProofreadResultFragment
    ) }
  )> }
);

export type ProofreadResultFragment = (
  { __typename?: 'ProofreadingResult' }
  & Pick<ProofreadingResult, 'ratingMessages'>
  & { body: Array<(
    { __typename?: 'Morpheme' }
    & Pick<Morpheme, 'id' | 'originalSurface' | 'surface'>
  )>, matches: Array<(
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'morphemeIds'>
  )>, requirementRuleResults: Array<(
    { __typename?: 'RequirementRuleResult' }
    & Pick<RequirementRuleResult, 'tagSet' | 'matchIds' | 'reason'>
  )>, mistakeRuleResults: Array<(
    { __typename?: 'MistakeRuleResult' }
    & Pick<MistakeRuleResult, 'tagSet' | 'matchIds' | 'reason'>
  )>, styleRuleResults: Array<(
    { __typename?: 'StyleRuleResult' }
    & Pick<StyleRuleResult, 'tagSet' | 'matchIds' | 'reason'>
  )> }
);

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = (
  { __typename?: 'Query' }
  & { tasks: Array<(
    { __typename?: 'Task' }
    & TaskSummaryFragment
  )> }
);

export type TaskSummaryFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'displayName' | 'gradeName' | 'description'>
);

export const TaskDetailFragmentDoc = gql`
    fragment taskDetail on Task {
  id
  displayName
  questionText
  figureUrl
  nextTaskId
}
    `;
export const ProofreadResultFragmentDoc = gql`
    fragment proofreadResult on ProofreadingResult {
  body {
    id
    originalSurface
    surface
  }
  matches {
    id
    morphemeIds
  }
  requirementRuleResults {
    tagSet
    matchIds
    reason
  }
  mistakeRuleResults {
    tagSet
    matchIds
    reason
  }
  styleRuleResults {
    tagSet
    matchIds
    reason
  }
  ratingMessages
}
    `;
export const TaskSummaryFragmentDoc = gql`
    fragment taskSummary on Task {
  id
  displayName
  gradeName
  description
}
    `;
export const TaskDetailDocument = gql`
    query TaskDetail($id: ID!) {
  task(id: $id) {
    ...taskDetail
  }
}
    ${TaskDetailFragmentDoc}`;

/**
 * __useTaskDetailQuery__
 *
 * To run a query within a React component, call `useTaskDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskDetailQuery(baseOptions: Apollo.QueryHookOptions<TaskDetailQuery, TaskDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskDetailQuery, TaskDetailQueryVariables>(TaskDetailDocument, options);
      }
export function useTaskDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskDetailQuery, TaskDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskDetailQuery, TaskDetailQueryVariables>(TaskDetailDocument, options);
        }
export type TaskDetailQueryHookResult = ReturnType<typeof useTaskDetailQuery>;
export type TaskDetailLazyQueryHookResult = ReturnType<typeof useTaskDetailLazyQuery>;
export type TaskDetailQueryResult = Apollo.QueryResult<TaskDetailQuery, TaskDetailQueryVariables>;
export const ProofreadDocument = gql`
    mutation Proofread($id: ID!, $text: String!) {
  proofread(input: {taskId: $id, text: $text}) {
    result {
      ...proofreadResult
    }
  }
}
    ${ProofreadResultFragmentDoc}`;
export type ProofreadMutationFn = Apollo.MutationFunction<ProofreadMutation, ProofreadMutationVariables>;

/**
 * __useProofreadMutation__
 *
 * To run a mutation, you first call `useProofreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProofreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proofreadMutation, { data, loading, error }] = useProofreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useProofreadMutation(baseOptions?: Apollo.MutationHookOptions<ProofreadMutation, ProofreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProofreadMutation, ProofreadMutationVariables>(ProofreadDocument, options);
      }
export type ProofreadMutationHookResult = ReturnType<typeof useProofreadMutation>;
export type ProofreadMutationResult = Apollo.MutationResult<ProofreadMutation>;
export type ProofreadMutationOptions = Apollo.BaseMutationOptions<ProofreadMutation, ProofreadMutationVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  tasks {
    ...taskSummary
  }
}
    ${TaskSummaryFragmentDoc}`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;