import React from "react";
import { render } from "react-dom";
import { App } from "~App";

const $app = document.getElementById("root")!;
function renderApp() {
  render(<App />, $app);
}

renderApp();

if ((module as any).hot) {
  (module as any).hot.accept(renderApp);
}
