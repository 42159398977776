/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useDashboardQuery, TaskSummaryFragment } from "../generated/graphql";
import {
  Spinner,
  Callout,
  Intent,
  Card,
  H5,
  Classes,
  H2,
  Divider,
  ButtonGroup,
  AnchorButton,
} from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import { intentClass } from "@blueprintjs/core/lib/esm/common/classes";

export type TopProps = {};

export const Top: React.FC<TopProps> = () => {
  const { loading, error, data } = useDashboardQuery();
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return (
      <Callout intent={Intent.DANGER} title={error.message}>
        {error.extraInfo}
      </Callout>
    );
  }
  const tasks = data!.tasks!;
  const gradeGroups = new Map<string, typeof tasks>();
  for (const task of tasks) {
    if (!gradeGroups.has(task.gradeName)) {
      gradeGroups.set(task.gradeName, []);
    }
    const group = gradeGroups.get(task.gradeName)!;
    group.push(task);
  }
  return (
    <React.Fragment>
      <ButtonGroup minimal>
        {[...gradeGroups.keys()].map((gradeName) => {
          return (
            <AnchorButton key={gradeName} large href={`#${gradeName}`}>
              {gradeName}
            </AnchorButton>
          );
        })}
      </ButtonGroup>
      <Divider className="mb-4" />
      {[...gradeGroups.entries()].map(([gradeName, tasks]) => (
        <GradeGroup key={gradeName} gradeName={gradeName} tasks={tasks} />
      ))}
    </React.Fragment>
  );
};

type GradeGroupProps = {
  gradeName: string;
  tasks: TaskSummaryFragment[];
};

const GradeGroup: React.FC<GradeGroupProps> = ({ gradeName, tasks }) => {
  return (
    <div className="row mb-3">
      <div className="col-12">
        <H2 id={gradeName}>{gradeName}</H2>
      </div>
      {tasks.map((task) => {
        return (
          <div className="col-3" key={task.id}>
            <TaskCard task={task} />
          </div>
        );
      })}
    </div>
  );
};

type TaskCardProps = {
  task: TaskSummaryFragment;
};

const TaskCard: React.FC<TaskCardProps> = ({ task }) => {
  return (
    <Card style={{ height: "100%" }}>
      <H5>{task.displayName}</H5>
      {task.description.split("\n").map((line, idx) => (
        <p key={idx}>{line}</p>
      ))}
      <Link
        to={`/tasks/${task.id}`}
        className={`${Classes.BUTTON} ${intentClass(Intent.PRIMARY)}`}
      >
        取り組む
      </Link>
    </Card>
  );
};
