/** @jsx jsx */
import { css, jsx, Global } from "@emotion/core";
import { AnchorButton, Classes, Navbar } from "@blueprintjs/core";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { Top } from "~containers/Top";
import { TaskDetail } from "~containers/TaskDetail";
import { iconClass } from "@blueprintjs/core/lib/esm/common/classes";
import { IconNames } from "@blueprintjs/icons";
import { BACKEND_BASE_URL } from "~config";

const globalStyle = css`
  body {
    font-size: 16px;
    padding-top: 64px;
  }
`;

const client = new ApolloClient({
  uri: BACKEND_BASE_URL + "/graphql",
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export const App = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Navbar fixedToTop>
        <Navbar.Group>
          <Navbar.Heading>さくらだより</Navbar.Heading>
          <Link
            to="/"
            className={`${iconClass(IconNames.HOME)} ${Classes.BUTTON} ${
              Classes.MINIMAL
            }`}
          >
            ホーム
          </Link>
          <AnchorButton
            href={`${BACKEND_BASE_URL}/manual`}
            target="_blank"
            rel="noreferrer"
            icon={IconNames.MANUAL}
            minimal
          >
            使い方
          </AnchorButton>
        </Navbar.Group>
      </Navbar>
      <section className="container-fluid">
        <Global styles={globalStyle} />
        <BrowserRouter></BrowserRouter>
        <Switch>
          <Route path="/" exact component={Top} />
          <Route path="/tasks/:id" exact component={TaskDetail} />
        </Switch>
      </section>
    </BrowserRouter>
  </ApolloProvider>
);
