/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {
  ProofreadResultFragment,
  TaskDetailFragment,
  useProofreadMutation,
  useTaskDetailLazyQuery,
} from "~generated/graphql";
import {
  Spinner,
  Callout,
  Intent,
  H1,
  H2,
  Checkbox,
  HTMLTable,
  Card,
  Button,
  Colors,
  ButtonGroup,
  Divider,
  AnchorButton,
} from "@blueprintjs/core";
import { useParams } from "react-router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { HighlightableEditor } from "~components/HighlightableEditor";
import { IconNames } from "@blueprintjs/icons";

type ProofreadResult = {
  inner: ProofreadResultFragment;
  matchIdsByMorphemeId: Map<number, Set<number>>;
  surfaceByMorphemeId: Map<number, string>;
  surfaceByMatchId: Map<number, string>;
  firstMorphemeIdByMatchId: Map<number, number>;
};

const useProofreadResult = (): [
  ReturnType<typeof useProofreadMutation>[0],
  ProofreadResult | null
] => {
  const [proofread, { data }] = useProofreadMutation();
  if (!data || !data.proofread) {
    return [proofread, null];
  }
  const inner = data.proofread.result;
  const { matches, body } = inner;
  const matchIdsByMorphemeId = new Map<number, Set<number>>();
  const surfaceByMorphemeId = new Map<number, string>();
  const surfaceByMatchId = new Map<number, string>();
  const firstMorphemeIdByMatchId = new Map<number, number>();
  for (const match of matches) {
    for (const morphemeId of match.morphemeIds) {
      if (!matchIdsByMorphemeId.has(morphemeId)) {
        matchIdsByMorphemeId.set(morphemeId, new Set([]));
      }
      const idSet = matchIdsByMorphemeId.get(morphemeId)!;
      idSet.add(match.id);
    }
  }
  for (const morpheme of body) {
    if (morpheme.id) {
      surfaceByMorphemeId.set(morpheme.id, morpheme.surface!);
    }
  }
  for (const match of matches) {
    const surface = match.morphemeIds
      .map((morphemeId) => surfaceByMorphemeId.get(morphemeId))
      .join("");
    surfaceByMatchId.set(match.id, surface);
  }
  for (const match of matches) {
    for (const morphemeId of match.morphemeIds) {
      if (
        !firstMorphemeIdByMatchId.has(match.id) ||
        morphemeId < firstMorphemeIdByMatchId.get(match.id)!
      ) {
        firstMorphemeIdByMatchId.set(match.id, morphemeId);
      }
    }
  }
  return [
    proofread,
    {
      inner,
      matchIdsByMorphemeId,
      surfaceByMorphemeId,
      surfaceByMatchId,
      firstMorphemeIdByMatchId,
    },
  ];
};

export type TaskDetailProps = {};
export const TaskDetail: React.FC<TaskDetailProps> = () => {
  const { id } = useParams<{ id: string }>();
  const [getTaskDetail, { loading, error, data }] = useTaskDetailLazyQuery();
  useEffect(() => {
    getTaskDetail({
      variables: {
        id,
      },
    });
  }, [getTaskDetail, id]);
  const [proofread, result] = useProofreadResult();
  const bottomNavRef = useRef(null as null | HTMLDivElement);
  useEffect(() => {
    if (result !== null) {
      if (bottomNavRef.current) {
        bottomNavRef.current.scrollIntoView();
      }
    }
  }, [result]);
  const [isEditing, setIsEditing] = useState(true);
  const [selectedMatchIds, setSelectedMatchIds] = useState(new Set<number>());
  const morphemes = useMemo(() => {
    if (!result) {
      return null;
    }
    const {
      inner: { body },
      matchIdsByMorphemeId,
    } = result;
    return body.map((mo) => {
      const isHighlighted = (() => {
        if (!mo.id) {
          return false;
        }
        const matchIds = matchIdsByMorphemeId.get(mo.id);
        if (!matchIds) {
          return false;
        }
        return [...matchIds].some((matchId) => selectedMatchIds.has(matchId));
      })();
      return {
        originalSurface: mo.originalSurface || "",
        isHighlighted,
      };
    });
  }, [result, selectedMatchIds]);
  if (loading || !data) {
    return <Spinner />;
  }
  if (error) {
    return (
      <Callout intent={Intent.DANGER} title={error.message}>
        {error.extraInfo}
      </Callout>
    );
  }
  const task = data.task!;
  if (!task) {
    return <Callout intent={Intent.WARNING} title="タスクが見つかりません" />;
  }
  return (
    <main className="row mb-5">
      <div className="col-8">
        <TaskQuestionSection task={task} />
        {result && (
          <ProofreadingResultSection
            isEditing={isEditing}
            result={result}
            selectedMatchIds={selectedMatchIds}
            onToggleMatch={(matchId) => {
              if (selectedMatchIds.has(matchId)) {
                const newSet = new Set([...selectedMatchIds]);
                newSet.delete(matchId);
                setSelectedMatchIds(newSet);
              } else {
                setSelectedMatchIds(new Set([...selectedMatchIds, matchId]));
              }
            }}
          />
        )}
        {result && !isEditing && (
          <div ref={bottomNavRef}>
            <Divider />
            <AnchorButton icon={IconNames.HOME} large minimal href="/">
              ホームに戻る
            </AnchorButton>
            &nbsp;
            {task.nextTaskId ? (
              <AnchorButton
                icon={IconNames.ARROW_RIGHT}
                large
                intent={Intent.SUCCESS}
                href={`/tasks/${task.nextTaskId}`}
              >
                次の問題へ
              </AnchorButton>
            ) : (
              <AnchorButton
                icon={IconNames.ARROW_RIGHT}
                large
                intent={Intent.SUCCESS}
                href={`https://hanadayori.overworks.jp/`}
              >
                上級へ進む
              </AnchorButton>
            )}
          </div>
        )}
      </div>
      <BodyEditorPane
        onCheck={(text) => {
          setIsEditing(false);
          proofread({ variables: { id, text } });
        }}
        onReopen={() => {
          setIsEditing(true);
          setSelectedMatchIds(new Set());
        }}
        isEditing={isEditing}
      >
        {morphemes &&
          morphemes.map((mo, idx) => (
            <span
              style={{
                backgroundColor: mo.isHighlighted ? "#f88" : "transparent",
              }}
              key={idx}
            >
              {mo.originalSurface}
            </span>
          ))}
      </BodyEditorPane>
    </main>
  );
};

type TaskQuestionProps = {
  task: TaskDetailFragment;
};

const figureStyle = css`
  max-width: 100%;
  height: autp;
`;

const TaskQuestionSection: React.FC<TaskQuestionProps> = ({ task }) => {
  return (
    <section>
      <H1>{task.displayName}</H1>
      {task.questionText.split(/\r?\n/).map((text, i) => (
        <p key={i}>{text}</p>
      ))}
      {task.figureUrl && <img css={figureStyle} src={task.figureUrl} />}
    </section>
  );
};

type ProofreadingResultSectionProps = {
  isEditing: boolean;
  selectedMatchIds: Set<number>;
  result: ProofreadResult;
  onToggleMatch: (matchId: number) => void;
};

const ProofreadingResultSection: React.FC<ProofreadingResultSectionProps> = ({
  isEditing,
  result: {
    inner: {
      ratingMessages,
      mistakeRuleResults,
      styleRuleResults,
      requirementRuleResults,
    },
    firstMorphemeIdByMatchId,
    surfaceByMatchId,
  },
  selectedMatchIds,
  onToggleMatch,
}) => {
  const mistakes = useMemo(() => {
    const normalMistakes = mistakeRuleResults
      .map((ruleResult) => {
        return ruleResult.matchIds.map((matchId) => {
          return {
            firstMorphemeId: firstMorphemeIdByMatchId.get(matchId)!,
            isSelected: selectedMatchIds.has(matchId),
            category: ruleResult.tagSet.join(","),
            match: surfaceByMatchId.get(matchId)!,
            matchId,
            reason: ruleResult.reason,
          };
        });
      })
      .reduce((a, b) => [...a, ...b], []);

    const styleMistakes = styleRuleResults
      .map((ruleResult) => {
        return ruleResult.matchIds.map((matchId) => {
          return {
            firstMorphemeId: firstMorphemeIdByMatchId.get(matchId)!,
            isSelected: selectedMatchIds.has(matchId),
            category: ruleResult.tagSet.join(","),
            match: surfaceByMatchId.get(matchId)!,
            matchId,
            reason: ruleResult.reason,
          };
        });
      })
      .reduce((a, b) => [...a, ...b], []);

    return [...normalMistakes, ...styleMistakes].sort(
      ({ firstMorphemeId: left }, { firstMorphemeId: right }) => left - right
    );
  }, [
    firstMorphemeIdByMatchId,
    mistakeRuleResults,
    selectedMatchIds,
    styleRuleResults,
    surfaceByMatchId,
  ]);
  const missingRequirements = useMemo(() => {
    return requirementRuleResults
      .filter((ruleResult) => {
        return ruleResult.matchIds.length === 0;
      })
      .map((ruleResult) => {
        return {
          category: ruleResult.tagSet.join(","),
          reason: ruleResult.reason,
        };
      });
  }, [requirementRuleResults]);
  return (
    <section>
      <RatingMessage ratingMessages={ratingMessages} />
      <MistakesTable
        isEditing={isEditing}
        mistakes={mistakes}
        onToggleMatch={onToggleMatch}
      />
      <MissingRequirementTable missingRequirements={missingRequirements} />
    </section>
  );
};

const RatingMessage: React.FC<{ ratingMessages: string[] }> = ({
  ratingMessages,
}) => {
  return (
    <section>
      <H2>コメント</H2>
      {ratingMessages.map((message, i) => (
        <p key={i}>{message}</p>
      ))}
    </section>
  );
};

type Mistake = {
  firstMorphemeId: number;
  isSelected: boolean;
  category: string;
  match: string;
  matchId: number;
  reason: string;
};

const MistakesTable: React.FC<{
  isEditing: boolean;
  mistakes: Mistake[];
  onToggleMatch: (matchId: number) => void;
}> = ({ isEditing, mistakes, onToggleMatch }) => {
  return (
    <div>
      <h2>直したほうがいい表現</h2>
      <HTMLTable width="100%">
        <tbody>
          {mistakes.map((mistake, i) => (
            <MistakeRow
              key={i}
              mistake={mistake}
              onToggleMatch={onToggleMatch}
              isEditing={isEditing}
            />
          ))}
        </tbody>
      </HTMLTable>
    </div>
  );
};

const MistakeRow: React.FC<{
  isEditing: boolean;
  mistake: Mistake;
  onToggleMatch: (matchId: number) => void;
}> = ({ isEditing, mistake, onToggleMatch }) => {
  return (
    <tr>
      <td>
        <Checkbox
          checked={mistake.isSelected}
          disabled={isEditing}
          onClick={() => onToggleMatch(mistake.matchId)}
        />
      </td>
      <td>{mistake.category}</td>
      <td>{mistake.match}</td>
      <td>{mistake.reason}</td>
    </tr>
  );
};

type MissingRequrement = {
  category: string;
  reason: string;
};

const MissingRequrementRow: React.FC<{
  missingRequirement: MissingRequrement;
}> = ({ missingRequirement }) => {
  return (
    <tr>
      <td>{missingRequirement.category}</td>
      <td>{missingRequirement.reason}</td>
    </tr>
  );
};

const MissingRequirementTable: React.FC<{
  missingRequirements: MissingRequrement[];
}> = ({ missingRequirements }) => {
  return (
    <div>
      <h2>使ったほうがいい表現</h2>
      <HTMLTable width="100%">
        <tbody>
          {missingRequirements.map((missingRequirement, i) => (
            <MissingRequrementRow
              key={i}
              missingRequirement={missingRequirement}
            />
          ))}
        </tbody>
      </HTMLTable>
    </div>
  );
};

const bodyEditorPaneStyle = css`
  position: fixed;
  bottom: 0;
  right: 8px;
  height: calc(100vh - 75px);

  > div {
    height: 100%;
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const bodyEditorWrapperStyle = css`
  flex: 1 1 auto;
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

const bodyEditorPaneFooterStyle = css`
  flex: 0 0 auto;
  padding: 16px;
  border-top: 1px solid ${Colors.GRAY5};
  background-color: ${Colors.LIGHT_GRAY5};
  text-align: right;
`;

type BodyEditorPaneProps = {
  onCheck: (text: string) => void;
  onReopen: () => void;
  isEditing: boolean;
};

const BodyEditorPane: React.FC<BodyEditorPaneProps> = ({
  children,
  onCheck,
  onReopen,
  isEditing,
}) => {
  const [text, setText] = useState("");
  return (
    <div css={bodyEditorPaneStyle} className="col-4">
      <Card elevation={3}>
        <div css={bodyEditorWrapperStyle}>
          <HighlightableEditor
            isColored={!isEditing && !!children}
            isInEdit={isEditing}
            isBold={false}
            placeholder={"本文をここに入力してください"}
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
          >
            {children}
          </HighlightableEditor>
        </div>
        <div css={bodyEditorPaneFooterStyle}>
          <ButtonGroup>
            <Button
              large
              icon={IconNames.EDIT}
              disabled={isEditing}
              onClick={() => onReopen()}
            >
              書き直す
            </Button>
            <Button
              large
              icon={IconNames.SEND_MESSAGE}
              intent={Intent.PRIMARY}
              disabled={!isEditing}
              onClick={() => onCheck(text)}
            >
              チェック
            </Button>
          </ButtonGroup>
        </div>
      </Card>
    </div>
  );
};
